<template>
  <!-- 医院结算 -->
  <div>
    <div>
      <!-- 送餐信息 -->
      <div class="deliverInfo">
        <div class="title">
          <div class="line"></div>
          <span class="info">送餐信息</span>
          <div class="line"></div>
        </div>
        <div class="infoDeliver">
          <!-- <van-field
            v-model="address.deliveryAddr"
            label="送餐地址:"
            label-width="70"
            placeholder="请输入送餐地址"
            right-icon="arrow"
          /> -->
          <span>送餐地址：{{ address.deliveryAddr }}</span>
        </div>
        <div class="infoDeliver">
          <span style="margin-right: 40px;">姓名：{{ address.receveName }}</span>
          <span>
            联系电话：{{ address.recevePhone }}
            <van-icon name="edit" v-if="!loginInfo" @click="recevePhoneEdit=true"/>
          </span>
        </div>
      </div>
      <!-- 订单信息 -->
      <div>
        <div class="title">
          <div class="line"></div>
          <span class="info">订单信息</span>
          <div class="line"></div>
        </div>
        <div>
          <div class="orderContain">
            <div v-for="(item, index) in orderList" :key="index">
              <div>
                <div
                  v-for="(goods, goodsIndex) in item.dishDetailList"
                  :key="goodsIndex"
                >
                  <div class="productCard">
                    <div class="iconImg">
                      <img :src="
                          goods.uploadFileList && goods.uploadFileList.length > 0 ? goods.uploadFileList[0].fileUrl : '' "
                        alt="" style="width: 77px;height: 77px;margin-left: 16px;"
                      />
                    </div>
                    <div class="priceNum">
                      <div class="titleLeft">
                        <div class="tiLle">{{ goods.dishName }}</div>
                        <div class="tiL">￥{{ goods.dishPrice }}</div>
                        <div class="tiL">x{{ goods.stepNum }}</div>
                      </div>
                      <div class="priceLine"></div>
                    </div>
                    <div class="priceRight">
                      <div>￥：{{ multiply(goods.dishPrice, goods.stepNum) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="calcPrice">
                <div>总计：</div>
                <div class="amount">￥{{ item.sumPrice }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- v-if="showpace" -->
    <div class="fix">
      <span style="font-size: 22px;font-weight: bold;margin-left: 18px;">￥{{ totalSum }}</span>
      <van-button type="primary" size="large" @click="ConPayment" >立即支付</van-button>
    </div>

    <div class="loadingbox" v-show="loading">
      <van-loading size="48px" vertical type="spinner" color="#F99502" class="loading" >下单中，请稍后...</van-loading>
    </div>

    <van-popup v-model="recevePhoneEdit" class="recevePhoneEdit">
        <div>
          <div style="margin-bottom:18px;margin-top:14px;font-weight: 600; padding: 0.6rem 3rem 0;">修改联系电话</div>
          <div class="contentPro" style="padding: 0.6rem 3rem;">
              <van-form validate-first  @submit="onSubmit">
                <div style="height: 5rem;">
                  <van-field
                    v-model="recevePhone"
                    type="tel"
                    name="联系电话"
                    label=""
                    placeholder="请填写联系电话"
                    :rules="[
                      { required: true, message: '联系电话不能为空' },
                      { message:'联系电话格式错误', pattern:new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)}
                    ]"
                    style="height: 4rem;"
                  >
                  </van-field>
                </div>
                <div class="page-bottom-btn">
                  <van-button type="primary" size="large" native-type="submit" style="width: 100%;">确认</van-button>
                </div>
              </van-form>
          </div>
        </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderList: [],
      totalSum: 0,
      reserveShow: false,
      loading: false,
      showpace: true,
      address: {
        hospitalId: "",
        receveName: "",
        recevePhone: "",
        deliveryAddr: "",
      },
      shoppingList:[],
      loginInfo: {},
      recevePhoneEdit: false,
      recevePhone: "",
    };
  },
  mounted() {
    this.shoppingList = this.$route.params.list;
    const roomInfo = JSON.parse(localStorage.getItem('roomInfo'));
    if(roomInfo.hospitalName||roomInfo.bunkNo){
      // this.address.deliveryAddr=roomInfo.hospitalName+'|'+roomInfo.floorMsg+'|'+roomInfo.bunkNo
      this.address.deliveryAddr=roomInfo.hospitalName+'|'+roomInfo.bunkNo
      this.address.hospitalId=roomInfo.hospitalId
    }
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if(userInfo.userName||userInfo.userPhone){
      this.address.receveName=userInfo.userName
      this.address.recevePhone=userInfo.userPhone
    }
    this.loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    let arr=[]
    let obj={
      openid: this.loginInfo ? this.loginInfo.openid : null,
      hospitalId: this.address.hospitalId,
      fareId:this.shoppingList.length>0?this.shoppingList[0].fareId:'',
      weakDay:this.shoppingList.length>0?this.shoppingList[0].weakDay:'',
      fareName:this.shoppingList.length>0?this.shoppingList[0].fareName:'',
      path:this.address.deliveryAddr,
      phone:this.address.recevePhone,
      name:this.address.receveName,
      sumPrice:'',
      dishDetailList:this.shoppingList,
    }
    arr.push(obj)
    let amount = 0;
    arr.forEach((item) => {
      item.dishDetailList.forEach((dish) => {
          amount += dish.stepNum * dish.dishPrice;
      });
      item.sumPrice = amount.toFixed(2)
      this.totalSum = amount.toFixed(2);
    });
    this.orderList=arr
  },
  methods: {
    multiply(num1, num2) {
      const num1Str = num1.toString();
      const num2Str = num2.toString();
      const num1Digits = num1Str.includes(".")
        ? num1Str.length - num1Str.indexOf(".") - 1
        : 0;
      const num2Digits = num2Str.includes(".")
        ? num2Str.length - num2Str.indexOf(".") - 1
        : 0;
      const precision = num1Digits + num2Digits;

      const num1Int = parseInt(num1Str.replace(".", ""));
      const num2Int = parseInt(num2Str.replace(".", ""));

      let result = num1Int * num2Int;
      result /= Math.pow(10, precision);

      return result;
    },
    onSubmit(){
      this.address.recevePhone = this.recevePhone;
      this.orderList[0].phone = this.recevePhone;
      this.recevePhoneEdit = false;
    },
    ConPayment() {
      this.loading = true;
      if (this.address.deliveryAddr == "") {
        this.loading = false;
        this.$toast.fail("送餐地址不能为空，请选择");
        return;
      }
      if (this.address.receveName == "") {
        this.loading = false;
        this.$toast.fail("收餐用户姓名不能为空，请至个人中心填写姓名");
        return;
      }
      if (this.address.recevePhone == "") {
        this.loading = false;
        this.$toast.fail("收餐用户电话不能为空，请至个人中心填写电话");
        return;
      }
      if (this.reserveShow) {
        this.loading = false;
        return;
      }
      let data = this.orderList[0];
      // let data = {
      //   fareId: "75",
      //   weakDay: "2024-07-12",
      //   fareName: "午餐",
      //   path: "第一人民医院3栋2楼2001",
      //   phone: "15800000000",
      //   name: "张三",
      //   sumPrice: "176",
      //   dishDetailList:[
      //     {
      //       dishId: 20,
      //       dishName: "干锅",
      //       dishPrice: 88,
      //       dishCount: 2,
      //       comboType: "2",
      //       comboDay: "",
      //     }
      //   ]
      // }
      this.reserveShow = true;
      if(this.loginInfo){
        this.$http.postAction("order/preOrder", data).then((res) => {
          this.reserveShow = false;
          if (res.code == 200) {
            if(!res.data||res.data.length==0||!res.data.appId){
              this.loading = false;
              this.$toast("下单失败，请重新下单")
            } else{
              this.loading = false;
              window.wx.miniProgram.navigateTo({
                url: `/pages/pay/mppay?payParam=${encodeURIComponent(JSON.stringify(res))}`
              })
            }
           
            // const data = {
            //   realAmount: this.orderList[0].sumPrice,
            //   data: res.data,
            // }
            // window.wx.miniProgram.navigateTo({
            //   url: `/pages/pay/pay?payParam=${encodeURIComponent(JSON.stringify(data))}`
            // })
            // this.$router.replace({ path: "/myOrder" });
          }else{
            this.loading = false;
            this.$toast(res.msg)
          }
        })
        .catch((e) => {
          this.loading = false;
          this.reserveShow = false;
        });
      } else{
        // 接口 app下单
        this.$http.postAction("order/orderByEquipment", data).then((res) => {
          this.reserveShow = false;
          if (res.code == 200) {
            this.loading = false;
            this.$toast("下单成功")
            this.$router.replace({ path: "/myOrder" });
          } else{
            this.loading = false;
            this.$toast(res.msg)
          }
        })
        .catch((e) => {
          this.loading = false;
          this.reserveShow = false;
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.deliverInfo {
  background-color: #f9f4f1;
  margin: 12px;
  border-radius: 15px;
  padding-bottom: 6px;
  // height: 140px;
  /deep/.van-cell {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 0.625rem 1rem;
    overflow: hidden;
    color: #323233;
    font-size: 0.875rem;
    line-height: 1.5rem;
    background-color: #f9f4f1;
  }
}
.addDeliver {
  display: flex;
  align-items: center;
}
.infoDeliver {
  margin-top: 10px;
  display: flex;
  margin-left: 15px;
  font-family: Source Han Sans;
  font-size: 14px;
  font-weight: normal;
  color: #3d3d3d;
  margin-bottom: 16px;
}
.orderContain {
  margin: 12px 26px;
  height: 52vh;
  overflow: auto;
  .productCard {
    width: 94%;
    // border-bottom: 0.5px solid #f3f3f3;
    align-items: center;
    background-color: #ffffff;
    display: flex;
    padding: 0.6rem 0.625rem;
    // border: 1px solid black;
    margin-bottom: 6px;
    .iconImg {
      display: flex;
      justify-content: space-around;
      align-items: center;
      // margin-left: 0.35rem;
      // border: 1px solid red;
    }
    .priceNum {
      width: 112px;
      display: flex;
      margin-left: 18px;
      justify-content: space-around;
      .titleLeft {
        font-size: 14px;
        font-weight: 350;
        .tiLle{
          margin-top: 4px;
          width: 84px;
          white-space: nowrap;
          overflow: hidden; /* 超出部分隐藏 */
          text-overflow: ellipsis;
        }
        .tiL {
          margin-top: 4px;
        }
      }
      .priceLine{
        width: 4.375rem;
        height: 2px;
        margin-top: 44px;
        margin-left: -42px;
        border-top:1px solid #D8D8D8
      }
    }
    .priceRight {
      font-size: 18px;
      font-weight: 350;
      text-align: right;
      // margin-top: 27px;
      margin-bottom:-22px;
    }
  }
}
.calcPrice {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  .amount {
    font-size: 14px;
    font-weight: 600;
  }
}
.title {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px 4px 5px;
  .info {
    font-size: 14px;
    font-weight: bold;
    color: #ffa800;
    margin-right: 6px;
  }
  .line {
    width: 40px;
    border-bottom: 2px solid #ffa800;
    margin-right: 6px;
    height: 8px;
  }
}
.fix {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 14px 20px;
  align-items: center;
  height: 2rem;
  position: fixed;
  bottom: 0;
  background: #fff;
  /deep/ .van-button--large {
    border-radius: 0.5rem;
    width: 8.4375rem;
    height: 2.425rem;
  }
}
.loadingbox {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  .loading {
    position: absolute;
    top: 40vh;
    left: 35vw;
  }
}
.recevePhoneEdit{
  /deep/ .van-field--error .van-field__control::placeholder {
    color: #969799  !important;
    font-weight: normal;
    font-size: 14px;
    -webkit-text-fill-color: currentColor;
  }
  /deep/.van-field__control {
    font-size: 14px;
    color: #969799 !important;
    font-weight: normal;
  }
}
</style>
